import { Search } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, FormControlLabel, Grid, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

  
export default ({ showInactive, setFilterPhrase, setShowInactive }) => {
  
  const onSearchPatient = (e) => {
    setFilterPhrase(e.target.value);
  }

  const onShowInactiveChange = () => {
    setShowInactive(!showInactive)    
  }


    return (
        <Grid container>
          <Grid container item xs={12} sx={{ my:2 }} alignItems="center">
            {/* <Grid item xs={12} md={4}>
              <TextField id="patient-search" 
                label="חפש מטופל" 
                onChange={onSearchPatient}
                sx={{ width: '100%'}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
              }}>
              </TextField>
            </Grid> */}

            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={!showInactive}
                    onChange={onShowInactiveChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    
                  />
                } label="הצג מטופלים פעילים בלבד" />
              {/* <Button
                component={Link}
                variant="contained"
                to="/patients/new">
                    הוספת מטופל
              </Button> */}
            </Grid>

          </Grid>        
        </Grid>
    )
   
  }