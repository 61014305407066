import { Grid, Typography } from "@mui/material";

export default function PagerHeader ({ children }) {
    return (
      <Grid item xs={12} sx={{bgcolor: 'primary.main', display: 'flex', alignItems: 'center', height: '60px', pr: '20px', px: '10px' }}>
        <Typography variant="h5" color='primary.contrastText'>
            {children}                    
        </Typography>
      </Grid>
    );
}