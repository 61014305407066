import { Link, useParams } from "react-router-dom";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import Preloader from '../../components/Shared/Preloader';
import { useFullPatientQuery } from '../../api';
import PageHeader from '../../components/Shared/PageHeader';
import PatientTimelineTab from './PatientTimelineTab';
import EditPatientView from "./EditPatientView";
import { useRouteMatch } from "../../utils";


export default function PatientDetailsView() {
  const { id: patientId } = useParams();
  const routeMatch = useRouteMatch([
    '/patients/:id',
    '/patients/:id/payments',
    '/patients/:id/info',
  ]);
  const currentTab = routeMatch?.pattern?.path;
  const { isLoading, isError, data: patient } = useFullPatientQuery(patientId)

  let content;
  if (isLoading) {
    content = (
      <Grid item xs={12}>
        <Preloader />
        <Typography>טוען תיק מטופל</Typography>
      </Grid>)
  } else if (isError) {
    content = (
      <Grid item xs={12}>
        <Typography>אירעה שגיאה</Typography>
      </Grid>)
  } else {
    content = (
      <div style={{ width: '100%' }}>
        <PageHeader>
          {patient.name}
        </PageHeader>
        <Box sx={{ bgcolor: 'primary.main', color: 'primary.contrastText', position: 'sticky', top: 0, zIndex: 'appBar' }}>
          <Tabs value={currentTab} indicatorColor='secondary' textColor='inherit'>
            <Tab label="ציר זמן" value="/patients/:id" to={`/patients/${patient.id}`} component={Link} />
            <Tab label="תשלומים" value="/patients/:id/payments" to={`/patients/${patient.id}/payments`} component={Link} />
            <Tab label="פרטים אישיים" value="/patients/:id/info" to={`/patients/${patient.id}/info`} component={Link} />
          </Tabs>
        </Box>
        <Box mx='10px' pt='10px'>
          {currentTab === '/patients/:id' && <PatientTimelineTab patient={patient} /> }
          {currentTab === '/patients/:id/payments' && <div>1</div> }
          {currentTab === '/patients/:id/info' && <EditPatientView /> }
        </Box>
      </div>
    )
  }

  return content;
}
