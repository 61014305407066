import { signInWithEmailAndPassword, AuthErrorCodes } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '../../components/Shared/TextField';
import { useCreateUserMutation } from "../../api";

export default () => {
  const createUserMutation = useCreateUserMutation()
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { control, handleSubmit } = useForm();
  const navigate = useNavigate()

  const onSubmit = ({ email, password }) => {
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(async () => {
        try {
          await createUserMutation.mutateAsync()
        } catch (error) {
          // swallow error if user already exists
          if (error.response.status !== 409) {
            throw error;
          }
        }
        setErrorMessage('')
        navigate('/')
      })
      .catch(error => {
        switch (error.code) {
          case AuthErrorCodes.INVALID_PASSWORD:
            setErrorMessage('סיסמא שגויה')
            break;
          case AuthErrorCodes.USER_DELETED:
            setErrorMessage('משתמש לא קיים')
            break;
          default:
            setErrorMessage(error.message)
            break;
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller name='email' control={control} render={({ field }) => <TextField {...field} label='אימייל' type='email' />} />
        <Controller name='password' control={control} render={({ field }) => <TextField {...field} type='password' label='סיסמא' />} />
        <LoadingButton loading={loading} variant="contained" type="submit">התחבר</LoadingButton>
      </form>
      <div>שגיאה: {errorMessage}</div>
    </div>
  )
}