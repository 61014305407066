import * as React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from "notistack";
import Fade from '@mui/material/Fade';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/he';
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { CssBaseline } from "@mui/material";
import { brown, lightGreen, pink } from "@mui/material/colors";
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient()
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});


const locale = 'he';
const root = ReactDOM.createRoot(
  document.getElementById("root")
);

const theme = createTheme({
  direction: 'rtl',
  h1: {
    margin: '5px'
  },
  typography: {
    fontFamily: ['Assistant, sans-serif'],
    allVariants: {
      color: brown[600],
    }
  },
  palette: {
    primary: {
      main: lightGreen[500],
      contrastText: '#ffffff',
    },
    secondary: {
      main: pink['A400'],
    },
    text: {
      primary: brown[600],
    },
    background: {
      default: '#f8f9fb',
    },
  },
}, locale);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <CacheProvider value={cacheRtl}>
            <SnackbarProvider TransitionComponent={Fade} dense autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
              <App />
            </SnackbarProvider>
            </CacheProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);