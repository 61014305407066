import * as React from 'react';
import { Grid } from '@mui/material';
import Calender from './Calender';
import PageHeader from '../../components/Shared/PageHeader';

export default function CalendarView() {
  return <Grid container>
    <PageHeader>
      יומן
    </PageHeader>
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8} sx={{ pt: 2 }}>
        <Calender />
      </Grid>
    </Grid>
  </Grid>;
}
