import { BottomNavigationAction } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function BottomNavMenuItem({ label, to, icon }) {
    const navigate = useNavigate();
    
    const goToPage = () => {
        navigate(to);        
    }

    return (         
        <BottomNavigationAction showLabel={true} label={label} onClick={goToPage} icon={icon} />
    );
  }
  
  