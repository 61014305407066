import { useNavigate } from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


export default function DrawerMenuItem({ title, to, icon }) {
  const navigate = useNavigate();

  const goToPage = () => {
    navigate(to);
  }

  return (
    <ListItem key={title} disablePadding>
      <ListItemButton onClick={goToPage}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>

  );
}

