import { Button, Card, CardContent, CardHeader, Fab, FormControl, Grid, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCurrentUserQuery, useMeetingQuery, usePatientsQuery, useUpdateMeetingMutation } from "../../api";
import Preloader from "../../components/Shared/Preloader";
import TextEditor from "../../components/Shared/TextEditor";
import { Controller, useForm } from "react-hook-form";
import PageHeader from "../../components/Shared/PageHeader";
import { DateTimePicker } from "@mui/x-date-pickers";
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from "notistack";
import { mobileBreakpoint } from "../../consts";

const statuses = [
  { id: 'scheduled', title: 'מתוכנן' },
  { id: 'canceled', title: 'מבוטל' },
  { id: 'done', title: 'הסתיים' },
  { id: 'paid', title: 'שולם' },
]

export default () => {
  const { id: meetingId } = useParams();
  const { isLoading: isLoadingUser, isError: isErrorUser, data: user } = useCurrentUserQuery();
  const { isLoading: isLoadingMeeting, isError: isErrorMeeting, data: meeting } = useMeetingQuery(meetingId)
  const { isLoading: isLoadingPatients, isError: isErrorPatients, data: patients } = usePatientsQuery();
  const mutation = useUpdateMeetingMutation()
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { serialId: '' },
    values: meeting,
  });
  const { enqueueSnackbar } = useSnackbar()

  const [summary, setSummary] = useState();

  useEffect(() => {
    if (meeting && !summary) {
      setSummary(meeting.summary);
    }
  }, [meeting]);
  useEffect(() => reset(meeting), [reset])

  let content;

  if (isLoadingMeeting || isLoadingPatients || isLoadingUser) {
    content = (
      <Grid item xs={12}>
        <Preloader />
        <Typography>טוען פגישה</Typography>
      </Grid>)
  } else if (isErrorMeeting || isErrorPatients || isErrorUser) {
    content = (
      <Grid item xs={12}>
        <Typography>אירעה שגיאה</Typography>
      </Grid>)
  } else {
    const startDateString = moment(meeting.start).locale('he').format('DD/MM/YYYY בשעה HH:mm');
    const patient = patients[meeting.patientId]

    async function onSubmit(data) {
      const payload = {
        id: meeting.id,
        ...data,
        summary,
      }
      try {
        await mutation.mutateAsync(payload)
        enqueueSnackbar('עודכן', { variant: 'success' })
      } catch {
        enqueueSnackbar('אירעה שגיאה', { variant: 'error' })
      }
    }

    content = (
      <Fragment>
        <Grid container sx={{ marginBottom: 6 }} justifyContent="center">
          <PageHeader>
            {startDateString} - {patient.name}
          </PageHeader>

          <Grid container item spacing={4} p={'10px'}>
            <Grid item xs={12} lg={4}>
              <Stack spacing={4}>
                <Paper>
                  <Card>
                    <CardHeader title="פרטי פגישה" />
                    <CardContent>
                      <FormControl component='form'>
                        <Grid container spacing={4} justifyContent="center">
                          <Grid item xs={10}>
                            <Controller
                              name="patientId"
                              control={control}
                              defaultValue={patient.id}
                              render={({ field }) =>
                                < TextField {...field} select label="מטופל" variant="standard" fullWidth>
                                  {Object.values(patients).map(p => <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>)}
                                </TextField>
                              }
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Controller
                              name="type"
                              control={control}
                              defaultValue={meeting.type}
                              render={({ field }) =>
                                < TextField {...field} select label="סוג" variant="standard" fullWidth>
                                  {Object.entries(user.appointmentTypes).map(([id, type]) => <MenuItem key={id} value={id}>{type.name}</MenuItem>)}
                                </TextField>
                              }
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Controller
                              name="start"
                              control={control}
                              defaultValue={meeting.start}
                              render={({ field }) =>
                                <DateTimePicker {...field} label="תאריך ושעה"
                                  renderInput={(params) => <TextField fullWidth variant="standard" {...params} />}
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Controller
                              name="serialId"
                              control={control}
                              render={({ field }) =>
                                <TextField {...field} label="מספר פגישה" variant="standard" type="number"
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={10}>
                            <Controller
                              name="status"
                              control={control}
                              defaultValue={meeting.status}
                              render={({ field }) =>
                                < TextField {...field} select label="סטטוס" variant="standard" fullWidth>
                                  {statuses.map(status => <MenuItem key={status.id} value={status.id}>{status.title}</MenuItem>)}
                                </TextField>
                              }
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </CardContent>
                  </Card>
                </Paper>
              </Stack>
            </Grid>

            <Grid item xs={12} lg={8}>
              <Stack spacing={4}>
                <Paper>
                  <Card>
                    <CardHeader title="סיכום פגישה" />
                    <CardContent>
                      <TextEditor value={summary} setValue={setSummary} />
                    </CardContent>
                  </Card>
                </Paper>

                <Paper>
                  <Card>
                    <CardHeader
                      title="קבצים"
                    // action={
                    //   <IconButton aria-label="settings">
                    //     <MoreVertIcon />
                    //   </IconButton>
                    // } 
                    />
                    <CardContent>

                    </CardContent>
                  </Card>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Fab variant="extended" color="primary" sx={{
          position: 'fixed',
          left: { xs: 10, [mobileBreakpoint]: 30 },
          top: { xs: 'auto', [mobileBreakpoint]: 30 },
          bottom: { xs: 70, [mobileBreakpoint]: 'auto' }
        }}>
          <SaveIcon sx={{marginRight: '5px' }} onClick={handleSubmit(onSubmit)} />
          שמור
        </Fab>
      </Fragment>
    )
  }

  return content;
}