import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import UpdateAppointmentTypeDialog from "./UpdateAppointmentTypeDialog";
import { omit } from "lodash";
import { useUpdateUserMutation } from "../../api";
import { useSnackbar } from "notistack";

export default function MeetingsSettingsView({ user }) {
  const mutation = useUpdateUserMutation();
  const { enqueueSnackbar } = useSnackbar()
  // TODO: learn how to manage dialogs, this open, setOpen is inconvenience 
  // currently did a hack which open may contain the type id 
  const [open, setOpen] = useState(false)

  async function deleteType(typeId) {
    try {
      await mutation.mutateAsync({
        appointmentTypes: omit(user.appointmentTypes, typeId),
      })
      enqueueSnackbar('עודכן', { variant: 'success' })
    } catch {
      enqueueSnackbar('אירעה שגיאה', { variant: 'error' })
    }
  }

  return <>
    <UpdateAppointmentTypeDialog open={open} setOpen={setOpen} user={user} typeId={open} />
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>סוג</TableCell>
            <TableCell>מחיר</TableCell>
            <TableCell>משך</TableCell>
            <TableCell>צבע</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(user.appointmentTypes).map(([id, type]) => (
            <TableRow
              key={type.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{type.name}</TableCell>
              <TableCell>{type.price}</TableCell>
              <TableCell>{type.duration}</TableCell>
              <TableCell>{type.color}</TableCell>
              <TableCell><EditIcon onClick={() => setOpen(id)} /></TableCell>
              <TableCell><DeleteIcon color='secondary' onClick={() => deleteType(id)}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <br />
    <Button onClick={() => setOpen(crypto.randomUUID())} variant='contained'>הוסף סוג טיפול</Button>
  </>
}