import moment from "moment";
import React, { useState } from 'react';
import { TextField, Autocomplete, createFilterOptions, Grid, FormControl, Typography, MenuItem } from '@mui/material';
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";

import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useCreateMeetingMutation, useCreateRecurrenceMutation, useCurrentUserQuery } from "../../api";

const filter = createFilterOptions();

export default ({ meeting, patients, closeDialog, openNewPatientDialog }) => {
  const { isLoading, isError, data: user } = useCurrentUserQuery();
  const mutation = useCreateMeetingMutation()
  const recurrenceMutation = useCreateRecurrenceMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false);

  const [meetingForm] = useState({
    patientId: meeting.patientId || null,
    type: '',
    date: meeting.start,
    startTime: meeting.start || Date.now(),
    endTime: meeting.end || new Date(meeting.start.getTime() + 1 * 60 * 60 * 1000) // plus 1 hour
  });

  const { control, handleSubmit } = useForm({
    defaultValues: meetingForm
  });

  const onSubmit = async (data) => {
    const start = moment(new Date(
      data.date.getFullYear(),
      data.date.getMonth(),
      data.date.getDate(),
      data.startTime.getHours(),
      data.startTime.getMinutes(),
      0,
      0
    ));
    const end = moment(new Date(
      data.date.getFullYear(),
      data.date.getMonth(),
      data.date.getDate(),
      data.endTime.getHours(),
      data.endTime.getMinutes(),
      0,
      0
    ));
    setLoading(true);
    const payload = {
      patientId: data.patientId,
      type: data.type,
      start: start,
      duration: end.diff(start, 'minute'),
    };

    try {
      if (data.rrule === 'no') {
        await mutation.mutateAsync(payload)
      } else {
        await recurrenceMutation.mutateAsync({...payload, rrule: data.rrule })
      }
      enqueueSnackbar('פגישה חדשה נוספה בהצלחה', { variant: 'success' })
      closeDialog()
    } catch {
      enqueueSnackbar('אירעה שגיאה', { variant: 'error' })
    } finally {
      setLoading(false);
    }
  }

  // TODO: we should unify this logic into one component 
  // learn the best practice
  if (isError) {
    return 'שגיאה'
  } else if (isLoading) {
    return 'טוען...'
  }

  const options = Object.values(patients).map(p => ({ label: p.name, id: p.id }))
  return (
    <FormControl component='form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1.3}>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <Autocomplete
                disablePortal
                options={options}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField label='מטופל' {...params} />}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.label);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      id: 'new',
                      label: `הוסף מטופל חדש: "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                {...field}
                value={options.find(o => o.id === field.value) || null}
                onChange={(e, data) => {
                  if (data?.id === 'new') {
                    openNewPatientDialog({ name: data.label })
                  } else {
                    field.onChange(data.id)
                  }
                }}
              />
            )}
            name="patientId"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="type"
            control={control}
            render={({ field }) =>
              <TextField
                label="סוג"
                {...field}
                fullWidth
                select
              >
                {Object.entries(user.appointmentTypes).map(([id, type]) => (
                  <MenuItem key={id} value={id}>{type.name}</MenuItem>
                ))}
              </TextField>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="date"
            control={control}
            render={({ field }) =>
              <DatePicker
                label='תאריך'
                {...field}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="startTime"
            control={control}
            render={({ field }) =>
              <TimePicker
                label='מ-'
                {...field}
                renderInput={(params) => <TextField label="מ-" {...params} />}
                onChange={(date) => field.onChange(date.toDate())}
              />}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="endTime"
            control={control}
            render={({ field }) =>
              <TimePicker
                label='עד-'
                {...field}
                renderInput={(params) => <TextField {...params} />}
                onChange={(date) => field.onChange(date.toDate())}
              />}
          />
        </Grid>
        <Grid xs={8}>
          <Controller
            name="rrule"
            control={control}
            defaultValue="no"
            render={({ field }) =>
              <TextField
                label="חזרה"
                {...field}
                fullWidth
                select
              >
                <MenuItem value="no">ללא</MenuItem>
                <MenuItem value="FREQ=WEEKLY">שבועי</MenuItem>
              </TextField>
            }
          />
        </Grid>
        <Grid xs={8}></Grid>
        <Grid item xs={4}>
          <LoadingButton loading={loading} variant="contained" type="submit" fullWidth>
            <Typography color="white">שמור</Typography>
          </LoadingButton>
        </Grid>
      </Grid>
    </FormControl>
  );
}