import moment from 'moment'
import React, { useEffect } from 'react';

import FullCalendar from '@fullcalendar/react' // must go before plugins

import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import './Calender.css'
import { useSwipeable } from 'react-swipeable';
import { useMediaQuery } from '@mui/material';
import { mobileBreakpoint } from '../../consts';
import AddButton from '../../components/Shared/AddButton';
import NewMeetingFormDialog from './NewMeetingFormDialog';
import { useNavigate } from 'react-router-dom';
import { useMeetingsQuery, usePatientsQuery } from '../../api';

export default () => {
  const calendarRef = React.useRef();
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false);
  const [start, setStart] = React.useState(null);
  const [end, setEnd] = React.useState(null);
  const [currentMeeting, setCurrentMeeting] = React.useState({});
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(mobileBreakpoint));
  const patientsQuery = usePatientsQuery()
  const meetingsQuery = useMeetingsQuery(start, end)

  let initialView
  let headerToolbar

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi();
    setStart(calendarApi.view.activeStart)
    setEnd(calendarApi.view.activeEnd)
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: (e) => {
      calendarRef.current.getApi().prev();
    },
    onSwipedRight: (e) => {
      calendarRef.current.getApi().next();
    },
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  if (isMobile) {
    initialView = "timeGridDay"
    headerToolbar = {
      right: '',
      center: '',
      left: 'today'
    }
  } else {
    initialView = "timeGridWeek"
    headerToolbar = {
      right: 'prev,next today',
      center: '',
      left: 'timeGridDay,timeGridWeek,dayGridMonth'
    }
  }
  const isLoading = meetingsQuery.isLoading || patientsQuery.isLoading,
    isError = meetingsQuery.isError || patientsQuery.isError,
    isSuccess = meetingsQuery.isSuccess && patientsQuery.isSuccess,
    patients = patientsQuery.data,
    meetings = meetingsQuery.data;

  let events = [];
  if (isSuccess) {
    for (const [id, meeting] of Object.entries(meetings)) {
      events.push({
        id: meeting.id,
        title: patients[meeting.patientId].name,
        start: new Date(meeting.start),
        end: new Date(moment(meeting.start).add(meeting.duration, 'minute'))
      })
    }
  }

  return (
    <div {...handlers}>
      <AddButton onClick={() => { setOpen(true) }} />
      <NewMeetingFormDialog open={open} setOpen={setOpen} meeting={currentMeeting} patients={patients} />
      <FullCalendar
        contentHeight='auto'
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={initialView}
        locale="he"
        headerToolbar={headerToolbar}
        dayHeaderFormat={{ weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric', omitCommas: true }}
        buttonText={{
          today: 'היום',
          month: 'חודש',
          week: 'שבוע',
          day: 'יום'
        }}
        allDaySlot={false}
        direction='rtl'
        editable={true}
        selectable={true}
        selectMirror={false}
        dayMaxEvents={true}
        snapDuration="01:00"
        slotMinTime="6:00"
        events={events}
        datesSet={(info) => {
          setStart(info.start)
          setEnd(info.end)
        }}
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
        }}
        select={(info) => {
          setOpen(true);
          setCurrentMeeting({
            start: info.start,
            end: info.end
          });
        }}
        
        longPressDelay={500}

        eventDurationEditable={false}
        titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
        defaultTimedEventDuration='01:00'
        forceEventDuration={true}
        eventClick={(info) => {
          const meetingId = info.event.id
          navigate(`/meetings/${meetingId}`);
        }}
        ref={calendarRef}
      />
    </div>);
}
