import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import PageHeader from "../../components/Shared/PageHeader";
import { Link } from "react-router-dom";
import { useRouteMatch } from "../../utils";
import MeetingsSettingsView from "./MeetingsSettingsView";
import Preloader from "../../components/Shared/Preloader";
import { useCurrentUserQuery } from "../../api";

export default () => {
  const { isError, isLoading, data: user } = useCurrentUserQuery()
  const routeMatch = useRouteMatch([
    '/settings',
  ]);
  const currentTab = routeMatch?.pattern?.path;
  if (isError) {
    return <Typography>אירעה שגיאה</Typography>
  } else if (isLoading) {
    return <Preloader />
  } else {
    return (
      <Grid container>
        <PageHeader>
          הגדרות
        </PageHeader>
        <Grid item xs={12}>
          <Box sx={{ bgcolor: 'primary.main', color: 'primary.contrastText', position: 'sticky', top: 0, zIndex: 'appBar' }}>
            <Tabs value={currentTab} indicatorColor='secondary' textColor='inherit'>
              <Tab label="פגישות" value="/settings" to={`/settings`} component={Link} />
            </Tabs>
          </Box>
          <Box mx='10px' pt='10px'>
            <Grid item xs={12}>
              {currentTab === '/settings' && <MeetingsSettingsView user={user} />}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  }
}
