import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import { drawerWidth, mobileBreakpoint } from '../../consts';
import { BottomNavigation, Drawer, List, Paper } from '@mui/material';
import DrawerMenuItem from './DrawerMenuItem';

import HomeIcon from '@mui/icons-material/Home';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TodayIcon from '@mui/icons-material/Today';
import SettingsIcon from '@mui/icons-material/Settings';
import BottomNavMenuItem from './BottomNavMenuItem';

const menuItems = [
  {title: 'בית', link: '/', icon: <HomeIcon color='primary' />},
  {title: 'מטופלים', link: '/patients', icon: <PeopleAltIcon color='primary' />},
  {title: 'יומן', link: '/calender', icon: <TodayIcon color='primary' />},
  {title: 'הגדרות', link: '/settings', icon: <SettingsIcon color='primary' />},
]

export default () => {
  
  return (
    <Box sx={{ display: 'flex', width: '100%', pb: 9 }}>
      {/* Drawer for web */}
      <Box
        component="nav"
        sx={{ width: { [mobileBreakpoint]: drawerWidth }, flexShrink: { [mobileBreakpoint]: 0 } }}
        aria-label="mailbox folders"
      >        
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', [mobileBreakpoint]: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <List>
            { menuItems.map((mi => <DrawerMenuItem key={mi.title} title={mi.title} to={mi.link} icon={mi.icon} />)) }            
          </List>
        </Drawer>        
      </Box>

      {/* BottomNavigation for mobile */}
      <Paper sx={{ 
          position: 'fixed', 
          zIndex: 'fab',
          bottom: 0, 
          left: 0, 
          right: 0,
          display: { xs: 'block', [mobileBreakpoint]: 'none' },
          height: '56px'
        }} 
        elevation={3}>
        <BottomNavigation>
          { menuItems.map((mi => <BottomNavMenuItem key={mi.title} label={mi.title} to={mi.link} icon={mi.icon} />)) }          
        </BottomNavigation>
      </Paper>

      {/* Current view */}
      <Outlet/>
    </Box>
  );
};