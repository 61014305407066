import React from 'react';
import { IconButton, Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme, Typography } from '@mui/material';
import NewMeetingForm from '../MeetingDetails/NewMeetingForm';
import { Close as CloseIcon } from '@mui/icons-material';
import NewPatientDialog from '../PatientsLog/NewPatientDialog';

export default ({ open, setOpen, meeting, patients }) => {
  const [patientOpen, setPatientOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    //TODO clear form
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <>
        <NewPatientDialog open={patientOpen} setOpen={setPatientOpen} />
        <Dialog fullScreen={fullScreen}
          open={open}
          onClose={handleClose}>
            <DialogTitle variant='h5'>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close">
                <CloseIcon />
              </IconButton>
              <br />
              יצירת פגישה חדשה
            </DialogTitle>

            <DialogContent style={{paddingTop: '10px'}}>
              <NewMeetingForm meeting={meeting} patients={patients} closeDialog={handleClose} openNewPatientDialog={() => { setPatientOpen(true) }} />
            </DialogContent>
            
          </Dialog>
      </>
    );
  }
