import { Grid } from "@mui/material";
import PageTitle from "../../components/Shared/PageHeader"

export default () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <PageTitle>
              ברוך הבא!
          </PageTitle>              
        </Grid>
      </Grid>
    );
  }
  