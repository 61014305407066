import keyBy from 'lodash/keyBy'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { auth } from "./firebase";

const api = axios.create({
  baseURL: '/api',
})

api.interceptors.request.use(async config => {
  config.headers['Authorization'] = 'Bearer ' + await auth.currentUser.getIdToken()

  return config
})

///////////////////////////
// Patients
///////////////////////////

const getPatients = async () => {
  return (await api.get('/patients')).data;
}

export function usePatientsQuery(options) {
  return useQuery({
    ...options,
    queryKey: ['GET', '/patients'],
    queryFn: async () => keyBy(await getPatients(), 'id'),
  })
}

export const getPatient = async (patientId) => {
  return (await api.get(`/patients/${patientId}?include=appointments&include=files`)).data;
}

export function useFullPatientQuery(id, options) {
  return useQuery({
    ...options,
    queryKey: ['GET', '/patients', id],
    queryFn: () => getPatient(id),
  })
}

export const createPatient = async (patient) => {
  return (await api.post('/patients', patient)).data;
}

export function useCreatePatientMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createPatient,
    onSuccess: (patient) => {
      queryClient.setQueryData(['GET', '/patients'], patients => ({ ...patients, [patient.id]: patient }))
    }
  })
}

export const updatePatient = async (patientId, patient) => {
  return (await api.patch(`/patients/${patientId}`, patient)).data;
}

export function useUpdatePatientMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, data }) => updatePatient(id, data),
    onSuccess: (patient) => {
      queryClient.setQueryData(['GET', '/patients'], patients => ({ ...patients, [patient.id]: patient }))
    }
  })
}

export const uploadPatientFile = async (patientId, filename, blob) => {
  const { data } = await api.post('/files/upload-signed-url', {
    resourceId: patientId,
    resourceType: "patient",
    filename,
  })

  await axios.put(data.url, blob)
}

///////////////////////////
// Meetings
///////////////////////////

export const getMeeting = async (id) => {
  return (await api.get(`/appointments/${id}`)).data;
}

export function useMeetingQuery(id, options) {
  return useQuery({
    ...options,
    queryKey: ['GET', '/appointments', id],
    queryFn: async () => await getMeeting(id),
  })
}

export const getMeetings = async (startDate, endDate) => {
  return (await api.get(`/appointments?date=${startDate}..${endDate}`)).data;
}

export function useMeetingsQuery(start, end, options) {
  const startDate = start?.toISOString().slice(0, 10)
  const endDate = end?.toISOString().slice(0, 10)
  return useQuery({
    ...options,
    queryKey: ['GET', '/appointments', startDate, endDate],
    queryFn: async () => keyBy(await getMeetings(startDate, endDate), 'id'),
    enabled: Boolean(start && end),
  })
}

export const createMeeting = async (meeting) => {
  return (await api.post('/appointments', meeting)).data;
}

export function useCreateMeetingMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createMeeting,
    onSuccess: (newMeeting) => {
      queryClient.setQueryData(['GET', '/appointments'], meetings => ({ ...meetings, [newMeeting.id]: newMeeting }))
      queryClient.setQueryData(['GET', '/appointments', newMeeting.id], newMeeting)
    }
  })
}

export const updateMeeting = async (id, data) => {
  return (await api.patch(`/appointments/${id}`, data)).data;
}

export function useUpdateMeetingMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({id, ...data}) => updateMeeting(id, data),
    onSuccess: (updatedMeeting) => {
      queryClient.setQueryData(['GET', '/appointments'], meetings => ({ ...meetings, [updatedMeeting.id]: updatedMeeting }))
      queryClient.setQueryData(['GET', '/appointments', updatedMeeting.id], updatedMeeting)
    }
  })
}

///////////////////////////
// Recurrences
///////////////////////////

export const createRecurrence = async (recurrence) => {
  return (await api.post('/recurrences', recurrence)).data;
}

export function useCreateRecurrenceMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createRecurrence,
  })
}

///////////////////////////
// Users
///////////////////////////

export const createUser = async () => {
  return (await api.post('/users', {})).data;
}

export function useCreateUserMutation() {
  return useMutation({
    mutationFn: createUser,
  })
}

const getCurrentUser = async () => {
  return (await api.get('/users/me')).data;
}

export function useCurrentUserQuery(options) {
  return useQuery({
    ...options,
    queryKey: ['GET', '/users/me'],
    queryFn: getCurrentUser,
  })
} 
const updateCurrentUser = async (payload) => {
  return (await api.patch('/users/me', payload)).data;
}

export function useUpdateUserMutation() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateCurrentUser,
    onSuccess: (user) => {
      queryClient.setQueryData(['GET', '/users/me'], user)
    }
  })
} 

