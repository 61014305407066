import { Add as AddIcon } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { mobileBreakpoint } from "../../consts";

export default function AddButton({ onClick }) {
    
    return (
        <Fab onClick={onClick} color="primary" aria-label="add" sx={{ 
            position: 'fixed', 
            left: { xs: 10, [mobileBreakpoint]: 30 }, 
            top: { xs: 'auto', [mobileBreakpoint]: 30},
            bottom: { xs: 70, [mobileBreakpoint]: 'auto'} 
        }}>
            <AddIcon />            
        </Fab>
    );
}