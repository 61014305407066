/**
 * NOTE: This file is a hack, please remove me! _/\_
 */

import { matchPath, useLocation } from "react-router-dom";

// TODO: move this or use an appropriate built-in function if available 
export function useRouteMatch(patterns) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}