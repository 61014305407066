export const MeetingStatusesHE = {
    'active': "פעילה",
    'cancelled': "בוטלה"
}

export const drawerWidth = 240;

export const loadingStatus = {
    PENDING: 'PENDING',
    FULFILLED: 'FULFILLED',
    IDLE: 'IDLE',
    REJECTED: 'REJECTED'
}

export const mobileBreakpoint = 'md'