import { Avatar, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';

import { useEffect, useState } from 'react';
import Preloader from '../../components/Shared/Preloader';
import { Link as LinkRouter } from 'react-router-dom';
import { Box } from '@mui/system';
import { usePatientsQuery } from '../../api';

const filterPatient = (patient, str, includeInactive) => {
  return (patient.name?.includes(str || '') || patient.idNumber?.includes(str || ''))
    && (includeInactive || patient.status === 'active')
}

const filterPatients = (patients, filterPhrase, showInactive) => {
  const fPatients = {}

  for (const [patientId, patient] of Object.entries(patients)) {
    if (filterPatient(patient, filterPhrase, showInactive)) {
      fPatients[patientId] = patient;
    }
  }

  return fPatients;
}

export default ({ filterPhrase, showInactive }) => {
  const { isLoading, isError, data: patients } = usePatientsQuery()
  const [filteredPatients, setFilteredPatients] = useState({});

  useEffect(() => {
    if (patients) {
      setFilteredPatients(filterPatients(patients, filterPhrase, showInactive));
    }
  }, [patients, filterPhrase, showInactive]);

  let content;
  if (isLoading) {
    content = (
      <Grid item xs={12}>
        <Preloader />
        <Typography>טוען מטופלים</Typography>
      </Grid>)
  } else if (isError) {
    content = (
      <Grid item xs={12}>
        <Typography>אירעה שגיאה</Typography>
      </Grid>)
  } else if (Object.entries(filteredPatients).length === 0) {
    content = (
      <Grid item xs={12}>
        <Typography variant="body1">לא נמצאו מטופלים</Typography>
      </Grid>)
  } else {
    content = Object.values(filteredPatients).map((patient) => (
      <Grid item xs={12} key={patient.id}>
        <Card sx={{ pt: '10px' }}>
          <CardContent>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              <Link
                component={LinkRouter}
                underline="none"
                to={`/patients/${patient.id}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
              >
                <Avatar sx={{ mr: '5px' }}>
                  {patient.name[0]}
                </Avatar>
                <Typography>
                  {patient.name}
                </Typography>
              </Link>

              <Link
                underline="none"
                href={`tel:${patient.phone}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',

                }}>
                <Typography sx={{ px: 2, display: { xs: 'none', md: 'block' } }}>{patient.phone}</Typography>
                <CallIcon></CallIcon>
              </Link>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    ))
  }

  return (
    <Grid container rowSpacing={1}>
      {content}
    </Grid>
  )

}