import React, { useState } from 'react';
import { IconButton, Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme, Typography, TextField } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useCreatePatientMutation } from '../../api';

export default function NewPatientDialog({ open, setOpen }) {
  const mutation = useCreatePatientMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
    //TODO clear form
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { control, handleSubmit } = useForm({
    defaultValues: {}
  });
  const onSubmit = async (data) => {
    try {
      await mutation.mutateAsync(data)
      enqueueSnackbar('מטופל נוספ.ה בהצלחה', { variant: 'success' })
      handleClose()
    } catch {
      enqueueSnackbar('אירעה שגיאה', { variant: 'error' })
    }
  }

  return (
    <Dialog fullScreen={fullScreen}
      sx={{}}
      open={open}
      onClose={handleClose}>
      <DialogTitle>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close">
          <CloseIcon />
        </IconButton>
        <Typography>
          מטופל חדש
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='name'
            control={control}
            render={({ field }) => <TextField label='שם' required {...field} />}
            value={null}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => <TextField label='טלפון' required {...field} />}
            value={null}
          />
          <LoadingButton loading={loading} variant="contained" type="submit">
            שמור
          </LoadingButton>
        </form>
      </DialogContent>
    </Dialog>
  );
}
