import { useParams } from "react-router-dom";
import { useFullPatientQuery } from "../../api";
import EditPatientForm from "./EditPatientForm";

export default function EditPatientView() {
  const { id } = useParams();
  const { data: patient } = useFullPatientQuery(id)

  return <EditPatientForm patient={patient} />;
}

