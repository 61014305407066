import { Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useUpdateUserMutation } from "../../api";
import { useSnackbar } from "notistack";

export default function UpdateAppointmentTypeDialog({ open, setOpen, user, typeId }) {
  const mutation = useUpdateUserMutation();
  const { enqueueSnackbar } = useSnackbar()
  const { control, handleSubmit } = useForm({
    values: { 
      name: user.appointmentTypes[typeId]?.name || '',
      price: user.appointmentTypes[typeId]?.price || '',
      duration: user.appointmentTypes[typeId]?.duration || '',
      color: user.appointmentTypes[typeId]?.color || '#000000',
    }
  });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    //TODO clear form
  }

  const onSubmit = async (data) => {
    try {
      await mutation.mutateAsync({
        appointmentTypes: { 
          ...user.appointmentTypes,
          [typeId]: data,
        }
      })
      enqueueSnackbar('עודכן', { variant: 'success' })
      setOpen(false)
    } catch {
      enqueueSnackbar('אירעה שגיאה', { variant: 'error' })
    } finally {
      setLoading(false);
    }
  };

  return <Dialog fullScreen
    open={Boolean(open)}
    onClose={handleClose}>
    <DialogTitle variant='h5'>
      <IconButton
        edge="start"
        color="inherit"
        onClick={handleClose}
        aria-label="close">
        <CloseIcon />
      </IconButton>
      <br />
      עריכת סוג טיפול
    </DialogTitle>

    <DialogContent style={{ paddingTop: '10px' }}>
      <FormControl component='form' onSubmit={handleSubmit(onSubmit)} fullWidth>
        <Grid container spacing={1.5}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name='name'
              render={({ field }) => <TextField label='שם' {...field} />}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Controller
              control={control}
              name='price'
              render={({ field }) => <TextField label='מחיר' {...field} />}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Controller
              control={control}
              name='duration'
              render={({ field }) => <TextField label='משך' {...field} />}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Controller
              control={control}
              name='color'
              render={({ field }) => <TextField type='color' label='צבע' {...field} sx={{ width: '70px' }} />}
            />
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <LoadingButton loading={loading} variant="contained" type="submit" fullWidth>
              <Typography color="white">שמור</Typography>
            </LoadingButton>
          </Grid>
        </Grid>
      </FormControl>
    </DialogContent>
  </Dialog>
} 