import { Grid } from '@mui/material';
import { useState } from 'react';
import PatientsLogContent from './PatientsLogContent';
import PatientsLogHeader from './PatientsLogHeader';
import AddButton from '../../components/Shared/AddButton';
import NewPatientDialog from './NewPatientDialog';
import PageHeader from '../../components/Shared/PageHeader';

export default function PatientsLogView() {
  const [open, setOpen] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [filterPhrase, setFilterPhrase] = useState(null);
  
  return (
    <Grid container justifyContent="center">
      <PageHeader>
        מטופלים
      </PageHeader>
      <Grid item xs={12} lg={8} px={'10px'}>        
        <PatientsLogHeader 
          setFilterPhrase={setFilterPhrase}
          setShowInactive={setShowInactive}
          showInactive={showInactive}
          filterPhrase={filterPhrase}
        />
        <PatientsLogContent filterPhrase={filterPhrase} showInactive={showInactive} />
        <NewPatientDialog open={open} setOpen={setOpen} />
        <AddButton onClick={() => { setOpen(true) }} />
      </Grid>
    </Grid>
  )
}