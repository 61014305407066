import moment from 'moment';
import { FormControl, InputLabel, MenuItem, Select, Typography, Link, Collapse } from "@mui/material";
import { FileUpload } from "../../components/Shared/FileUpload";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Star, Handshake, AttachFile } from '@mui/icons-material';
import { orderBy } from "lodash";
import { useState } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import PatientTimelineTabMeeting from './PatientTimelineItemMeeting';
import PatientTimelineTabFile from './PatientTimelineItemFile';
import PatientTimelineTabEvent from './PatientTimelineItemEvent';



export default function PatientTimelineTab({ patient }) {
  const [filter, setFilter] = useState('all');

  function updateFilter(event) {
    setFilter(event.target.value)
  }

  const timeline = orderBy([
    ...patient.appointments.map(a => ({
      type: 'meeting',
      time: a.start,
      title: `פגישה ${a.serialId}`,
      id: a.id,
      summary: a.summary,
      
    })),
    ...patient.files.map(f => ({
      type: 'file',
      time: f.createdAt,
      title: f.filename
    })),
    {
      type: 'created',
      time: patient.createdAt,
      title: 'המטופל נוסף'      
    }
  ], 'time', 'desc')
    .filter(event => filter === 'all' | filter === event.type + 's')

  const lastKey = timeline.length - 1;
  const timelineItems = timeline.map((event, i) => {
    switch (event.type) {
      case 'meeting':
        return <PatientTimelineTabMeeting event={event} key={i} tKey={i} tLastKey={lastKey}/>
      case 'file':
        return <PatientTimelineTabFile event={event} key={i} tKey={i} tLastKey={lastKey}/>
      case 'created':
        return <PatientTimelineTabEvent event={event} key={i} tKey={i} tLastKey={lastKey}/>
    }
  })

  return <>
    <FileUpload resourceId={patient.id}></FileUpload>
    <FormControl fullWidth sx={{ mt: '15px' }}>
      <InputLabel id="patient-timeline-filter">סנן</InputLabel>
      <Select 
        labelId='patient-timeline-filter' 
        label='סנן' 
        defaultValue='all' 
        onChange={updateFilter} 
        // center the popover. a small hack but should be ok for now.
        MenuProps={{ sx: { right: '-6px' } }}
      >
        <MenuItem value='all'>הכל</MenuItem>
        <MenuItem value='meetings'>פגישות</MenuItem>
        <MenuItem value='payments'>תשלומים</MenuItem>
        <MenuItem value='files'>קבצים</MenuItem>
      </Select>
    </FormControl>
    <Timeline>
      {timelineItems}
    </Timeline>
  </>
}