import { CircularProgress } from "@mui/material";

export default ({ }) => {
    return (
        <CircularProgress/>
        
    );
}


