import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default ({ value, setValue }) => {
  const editorRef = useRef(null);
  return (
    <Editor
      onInit={(evt, editor) => editorRef.current = editor}
      value={value}
      onEditorChange={value => setValue(value)}
      init={{
        height: 300,
        menubar: false,
        branding: false,
        statusbar: false,
        language: 'he_IL',
        toolbar_mode: 'sliding',
        mobile: {
          toolbar_mode: 'sliding'
        },
        plugins: [
          'directionality', 'lists', 'link'
        ],
        toolbar: 'underline bold italic styles alignright aligncenter alignleft numlist bullist rtl ltr link',
        content_style: "@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;700&display=swap');body { font-family:Assistant,sans-serif; font-size:1rem; line-height: 1rem;font-weight: 400 }",
        style_formats: [
          { title: 'גודל 1', block: 'h1' },
          { title: 'גודל 2', block: 'h2' },
          { title: 'גודל 3', block: 'h3' },
          { title: 'רגיל', block: 'p' }
        ]
      }}
    />

  );
}
