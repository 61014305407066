import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDvx-EiJ6j-dnA-lKjasIGfolJC7B10E3k",
  authDomain: "clinical-9f430.firebaseapp.com",
  projectId: "clinical-9f430",
  storageBucket: "clinical-9f430.appspot.com",
  messagingSenderId: "985030645055",
  appId: "1:985030645055:web:a3854f30c21761773bef8a"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
