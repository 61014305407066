import moment from 'moment';
import { FormControl, InputLabel, MenuItem, Select, Typography, Link, Collapse } from "@mui/material";
import { FileUpload } from "../../components/Shared/FileUpload";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Star, Handshake, AttachFile } from '@mui/icons-material';
import { orderBy } from "lodash";
import { useState } from 'react';
import { Link as LinkRouter } from 'react-router-dom';



export default function PatientTimelineTabEvent({ event, tKey, tLastKey }) {
    const [open, setOpen] = useState(false);

    return <TimelineItem sx={{flexDirection: 'row-reverse'}}>
      <TimelineContent>
        <Typography onClick={() => { setOpen(!open) }} sx={{ fontWeight: 'bold' }}>{event.title}</Typography>
        <Collapse orientation="vertical" in={open}>
          {/* <Typography sx={{ fontWeight: 'light' }}>
            {event.summary || <Typography sx={{fontStyle: 'italic'}} component={'span'}>טרם הוזן סיכום פגישה</Typography> }
          </Typography>       */}
        </Collapse>
        <Typography variant='caption' sx={{ color: 'grey' }}>{moment(event.time).format('HH:mm DD.MM.YYYY')}</Typography>
      </TimelineContent>
      <TimelineSeparator>
        <TimelineDot sx={{ background: '#bb8044' }} >
          <Star/>       
        </TimelineDot>
        {tKey < tLastKey && <TimelineConnector />}
      </TimelineSeparator>
    </TimelineItem>  
}