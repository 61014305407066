import { Card, CardContent, Fab, Grid, Switch, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SaveIcon from '@mui/icons-material/Save';
import { useUpdatePatientMutation } from "../../api";
import { isEmpty, omitBy } from "lodash";
import { mobileBreakpoint } from "../../consts";

const personalDetailsFormFields = [{
  name: "name",
  type: "text",
  label: "שם"
}, {
  name: "phone",
  type: "text",
  label: "טלפון"
}, {
  name: "address",
  type: "text",
  label: "כתובת"
}, {
  name: "idNumber",
  type: "text",
  label: "ת.ז."
}, {
  name: "email",
  type: "text",
  label: "אימייל"
}]

export default function EditPatientForm({ patient }) {
  const mutation = useUpdatePatientMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false);

  const style = { width: '100%', marginBottom: 2 };
  const { control, handleSubmit } = useForm({
    defaultValues: {
      ...omitBy(patient, 'appointments'),
      ...contactsToFields(patient.contacts),
    },
  });

  const onSubmit = async (data) => {
    data.status = data.status ? 'active' : 'inactive'
    data.contacts = [
      { name: data.contactPerson1Name, relation: data.contactPerson1Relation, phone: data.contactPerson1Phone },
      { name: data.contactPerson2Name, relation: data.contactPerson2Relation, phone: data.contactPerson2Phone },
    ]
    setLoading(true);
    try {
      await mutation.mutateAsync({ id: data.id, data: omitBy(data, isEmpty) })
      enqueueSnackbar('הפרטים נשמרו בהצלחה', { variant: 'success' })
    } catch {
      enqueueSnackbar('אירעה שגיאה', { variant: 'error' })
    }

    setLoading(false)
  }

  return (
    <Fragment>
      <form>
        <Card>
          <CardContent>
            {personalDetailsFormFields.map((fieldData) =>
              <Controller
                name={fieldData.name}
                key={fieldData.name}
                control={control}
                render={({ field }) =>
                  <TextField
                    {...field}
                    sx={style}
                    variant="standard"
                    type={fieldData.type}
                    label={fieldData.label}
                  />}
              />
            )}
            <Controller
              name="status"
              key="status"
              control={control}
              render={({ field }) => (
                patient.status === 'active'
                  ? <Switch {...field} defaultChecked />
                  : <Switch {...field} />
              )
              }
            />
          </CardContent>
        </Card>
        <Card sx={{ mt: '10px' }}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>איש קשר 1</Grid>
              <Grid item xs={6}>
                <Controller
                  name="contactPerson1Name"
                  key="contactPerson1Name"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      {...field}
                      sx={style}
                      variant="standard"
                      type="text"
                      label="שם"
                    />}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="contactPerson1Relation"
                  key="contactPerson1Relation"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      {...field}
                      sx={style}
                      variant="standard"
                      type="text"
                      label="קרבה"
                    />}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="contactPerson1Phone"
                  key="contactPerson1Phone"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      {...field}
                      sx={style}
                      variant="standard"
                      type="text"
                      label="טלפון"
                    />}
                />
              </Grid>
              <Grid item xs={12}>איש קשר 2</Grid>
              <Grid item xs={6}>
                <Controller
                  name="contactPerson2Name"
                  key="contactPerson2Name"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      {...field}
                      sx={style}
                      variant="standard"
                      type="text"
                      label="שם"
                    />}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="contactPerson2Relation"
                  key="contactPerson2Relation"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      {...field}
                      sx={style}
                      variant="standard"
                      type="text"
                      label="קרבה"
                    />}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="contactPerson2Phone"
                  key="contactPerson2Phone"
                  control={control}
                  render={({ field }) =>
                    <TextField
                      {...field}
                      sx={style}
                      variant="standard"
                      type="text"
                      label="טלפון"
                    />}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

      </form>
      <Fab variant="extended" color="primary" sx={{
        position: 'fixed',
        left: { xs: 10, [mobileBreakpoint]: 30 },
        top: { xs: 'auto', [mobileBreakpoint]: 30 },
        bottom: { xs: 70, [mobileBreakpoint]: 'auto' }
      }}>
        <SaveIcon sx={{ marginRight: '5px' }} onClick={handleSubmit(onSubmit)} />
        שמור
      </Fab>
    </Fragment>
  );
}

function contactsToFields(contacts) {
  return {
    contactPerson1Name: contacts[0]?.name || '',
    contactPerson1Relation: contacts[0]?.relation || '',
    contactPerson1Phone: contacts[0]?.phone || '',
    contactPerson2Name: contacts[1]?.name || '',
    contactPerson2Relation: contacts[1]?.relation || '',
    contactPerson2Phone: contacts[1]?.phone || '',
  }
}