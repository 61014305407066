import { useForm } from "react-hook-form";
import { uploadPatientFile } from "../../api";

export const FileUpload = ({ resourceId }) => {
  const { register, handleSubmit, formState:{ errors } } = useForm();

  const onSubmit = async ({ files }) => {
    const file = files[0]
    uploadPatientFile(resourceId, file.name, await file.arrayBuffer())
      .catch(console.log)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="file" {...register("files", {
        validate: { lessThan2MB: ([file]) => file.size < 2 * 1024 * 1024 || 'הקובץ גדול מ-2MB' }
      })} />
      <p>{errors.files?.message}</p>

      <input type="submit" />
    </form>
  )
}
