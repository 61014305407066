import { useState } from 'react';
import MainLayout from './components/MainLayout/Layout';
import { Routes, Route } from "react-router-dom";
import "./App.css";
import './firebase';

import LoginView from './features/Login/LoginView';

import EditPatientView from './features/PatientDetails/EditPatientView';
import PatientDetailsView from './features/PatientDetails/PatientDetailsView';
import HomeView from './features/Home/HomeView';

import PatientsLogView from './features/PatientsLog/PatientsLogView';
import NewMeetingFormDialog from './features/MeetingsCalender/NewMeetingFormDialog';
import CalenderView from './features/MeetingsCalender/CalenderView';
import MeetingDetailsView from './features/MeetingDetails/MeetingDetailsView';
import Protected from './features/Login/Protected';
import { auth } from './firebase';
import SettingsView from './features/Settings/SettingsView';

function App() {
  const [initializing, setInitializing] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  auth.onAuthStateChanged((user) => {
    if (user) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    if (initializing) setInitializing(false);
  });

  if (initializing) return null 

  return (
    <div className="App">
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Protected isLoggedIn={loggedIn}><HomeView /></Protected>}></Route>
            <Route path="/login" element={<LoginView />} />
            
            <Route path="/patients" element={<Protected isLoggedIn={loggedIn}><PatientsLogView /></Protected>} />
            <Route path="/patients/new" element={<Protected isLoggedIn={loggedIn}><EditPatientView /></Protected>} />
            <Route path="/patients/:id" element={<Protected isLoggedIn={loggedIn}><PatientDetailsView /></Protected>} />
            <Route path="/patients/:id/payments" element={<Protected isLoggedIn={loggedIn}><PatientDetailsView /></Protected>} />
            <Route path="/patients/:id/info" element={<Protected isLoggedIn={loggedIn}><PatientDetailsView /></Protected>} />
          
            <Route path="/meetings/new" element={<Protected isLoggedIn={loggedIn}><NewMeetingFormDialog /></Protected>} />
            <Route path="/calender" element={<Protected isLoggedIn={loggedIn}><CalenderView /></Protected>} />
            <Route path="/meetings/:id" element={<Protected isLoggedIn={loggedIn}><MeetingDetailsView /></Protected>} />

            <Route path="/settings" element={<Protected isLoggedIn={loggedIn}><SettingsView /></Protected>} />
          </Route>
        </Routes>
    </div>
  );
}

export default App;
